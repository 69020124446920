<template>
    <v-app>
        <v-main>
            <Buscador/>
        </v-main>
    </v-app>
</template>
<script>
import Buscador from '../components/buscador/Buscador'
export default {
    components: {
        Buscador
    },
    data(){
        return {

        }
    },
    created(){
        console.log('xdsssssssssssssssssssssssss')
    }
}
</script>