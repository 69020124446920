<template>
    <v-row>
        <v-col v-if="archivos_carpeta_seleccionada.length > 0">
                <v-row>
                    <v-col class="mx-auto" cols="12" sm="8">
                        <Paginacion :cantidad_paginas="archivos_carpeta_seleccionada.length" class="my-3" @buscar="pagina=$event-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-window v-model="pagina">
                            <v-window-item v-for="(archivo, k) in archivos_carpeta_seleccionada" :key="k"  >
                                <v-sheet
                                    class="mx-auto"
                                    max-width="75%"
                                    color=" "
                                    elevation=""
                                >
                                    <v-container>
                                    <v-row>
                                        <v-col>
                                        <Archivos
                                            :mostrar_carpeta_publica="true"
                                            :archivo="archivo"
                                            :actions="actions"
                                            :width="800"
                                            :height="500"
                                        />
                                        </v-col>
                                    </v-row>
                                    </v-container>
                                </v-sheet>
                            </v-window-item>
                        </v-window>
                    
                    </v-col>
                </v-row>
        </v-col>
        <v-col v-else>
            <NotFound :mensaje="'No hay archivos subidos'" />
        </v-col>
    </v-row>
</template>
<script>
import Archivos from '../Archivos.vue'
import NotFound from '../atomos/NotFound.vue'
import Paginacion from '../atomos/Paginacion.vue'
export default {
    data(){
        return {
            pagina: 0,
        }
    },
    components: {
        Archivos,
        NotFound,
        Paginacion,
    },
    props: {
        actions: {
            type: String,
            default: 'NO_ACTIONS'
        },
        archivos_carpeta_seleccionada: {
            type: Array,
            required: true
        }
    }
}
</script>