<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-card v-on:keydown.enter="buscar()">
        <v-row>
          <v-col cols="">
            <v-container fluid>
              <v-sheet elevation="5" class="pa-4">
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
                  v-show="load"
                ></v-progress-linear>

                <v-toolbar rounded="" col or="indigo darken-4" dark>
                  <v-toolbar-title class="headline font-weight-light">
                    Buscadores
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <slot name="accion"></slot>
                </v-toolbar>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="">
                      <div
                        v-text="
                          'Selecciona un tipo de filtro por el cual deseas buscar un documentos posteriormente, coloca su descripcion'
                        "
                        class="subtitle-1 font-weight-light"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        @change="tipoFiltro"
                        prepend-icon="mdi-clipboard-text-outline"
                        rounded
                        filled
                        dense
                        return-object
                        item-text="nombre"
                        v-model="tipo_documental_seleccionado"
                        label="Escoge el tipo documental"
                        :items="tipos_documentales"
                      />
                    </v-col>
                    <v-col cols="">
                      <v-autocomplete
                        rounded
                        filled
                        dense
                        prepend-icon="mdi-clipboard-text-outline"
                        v-model="campo_seleccionado"
                        label="Buscar por: "
                        :items="campos_tipo_documental"
                        item-text="nombre"
                        return-object
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="(campo, index) in campo_seleccionado"
                      :key="index"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        rounded
                        filled
                        dense
                        v-model="campo.valor"
                        :label="`Ingresa: ${campo.nombre}`"
                        append-icon="mdi-magnify"
                        @click:append="buscar()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid v-if="notFound == true">
                  <v-row align="center" justify="center">
                    <div
                      v-text="
                        'No existen coincidencias en la busqueda ' +
                          error.message
                      "
                      class="title font-weight-light"
                    ></div>
                  </v-row>
                  <v-row class="mx-auto">
                    <!--  -->
                    <img
                      :src="require('../../assets/notFound.png')"
                      class="mx-auto"
                      alt="Datos no encontrados"
                      contain
                      width="300"
                    />
                  </v-row>
                </v-container>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
      </v-card>
    </v-window-item>
    <v-window-item :value="2">
      <v-sheet>
        <Toolbar :activar_tool="false" :titulo="'Resultados...'">
          <template v-slot:accion_retroceder>
            <v-btn dark icon @click="step--"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
          </template>
        </Toolbar>
        <Paginacion
          :cantidad_paginas="parseInt(cantidad_paginas)"
          class="my-3"
          @buscar="page = $event"
        />
        <v-data-table
          :headers="headers"
          :items="desserts"
          class="mx-10"
          max-width="650"
          :search="search"
          :items-per-page="250"
          hide-default-footer
          :page.sync="page"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> Resultados </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4">
                <v-text-field
                  :append-outer-icon="'mdi-magnify'"
                  label="Buscar"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-toolbar>
          </template>
          <template v-slot:item.datos_archivos="{ item }">
            <v-btn
              color="indigo"
              dark
              class="mb-2"
              icon
              @click="revisar_archivo_seleccionado(item)"
            >
              <v-icon> mdi-folder-information-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.estatus="{ item }">
            <v-chip
              dark
              :color="item.estatus == 'HABILITADA' ? 'green' : 'orange'"
            >
              {{ item.estatus }}
              <v-tooltip
                v-if="
                  $store.state.dataUser.permisos_buscador[
                    'Buscador completo'
                  ] && item.estatus == 'INHABILITADA'
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="indigo"
                    dark
                    class="mt-0"
                    icon
                    @click="habilitar_busqueda(item)"
                  >
                    <v-icon> mdi-reload-alert</v-icon>
                  </v-btn>
                </template>
                <span>Recuperar busqueda</span>
              </v-tooltip>
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="green"
              class="mb-2"
              :disabled="!$store.state.dataUser.permisos_buscador.Editar"
              icon
              @click="editar_inputs(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              color="red"
              class="mb-2"
              :disabled="!$store.state.dataUser.permisos_buscador.Eliminar"
              icon
              @click="set_busqueda_inhabilitar(item)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
            <v-btn
              v-if="$store.state.dataUser.dataUser.objectId == 'bRSwTdK6aF'"
              color="indigo"
              class="mb-2"
              :disabled="!$store.state.dataUser.permisos_buscador.Eliminar"
              icon
              @click="eliminar_busqueda(item)"
            >
              <v-icon> mdi-hammer-screwdriver </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
      <v-dialog v-model="dialogo_revisar_archivo_seleccionado" fullscreen>
        <Card
          :titulo="'Revisar archivo seleccionado'"
          @activar="cerrar_revision_archivos()"
        >
          <template v-slot:contenido>
            <Carpetas :carpetas.sync="$store.state.buscador.carpetas" />
          </template>
        </Card>
      </v-dialog>
      <v-dialog v-model="dialogo_inhabilitar" max-width="400">
        <Card
          :titulo="'Inhabilitar busqueda'"
          @activar="dialogo_inhabilitar = false"
        >
          <template v-slot:contenido>
            <br />
            <h3>
              Estás seguro de inhabilitar {{ busqueda_inhabilitar.Placa }} :
              {{ busqueda_inhabilitar.Tipo_de_tramite }}
            </h3>
            <br />
            <v-btn
              block
              color="red"
              dark
              @click="inhabilitar_busqueda(busqueda_inhabilitar)"
              >Eliminar</v-btn
            >
          </template>
        </Card>
      </v-dialog>
      <v-dialog v-model="dialogo_editar" max-width="600" persistent>
        <Card
          :load="load_editar"
          :titulo="'Editar informacion'"
          @activar="dialogo_editar = false"
        >
          <template v-slot:contenido>
            <v-row
              class="mt-2"
              v-for="(input, index) in headers_editar"
              :key="index"
            >
              <v-text-field
                prepend-icon="mdi-book"
                filled
                rounded
                dense
                :label="input.text"
                v-model="informacion_editar[input.value]"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn
                @click="
                  editar_informacion_endpoint(
                    headers_editar,
                    informacion_editar
                  )
                "
                block
                text
                color="green"
              >
                Editar
              </v-btn>
            </v-row>
          </template>
        </Card>
      </v-dialog>
    </v-window-item>
  </v-window>
</template>
<script>
const axios = require("axios").default;
//import RevisarArchivos from './celulas/RevisarArchivos.vue'
import { EventBus } from "../../event/event-bus";
//import NotFound from "../components/NotFound";
import folders from "../../mixins/folders";
import Carpetas from "./Carpetas.vue";
export default {
  mixins: [folders],
  components: {
    //Carousel,
    //NotFound,
    Carpetas,
  },
  data() {
    return {
      dialogo_agregar: false,

      step: 1,

      filtro: "",

      tipoFiltroSelect: "",
      resultados: [],

      dialogo_editar: false,
      informacion_editar: {},
      headers_editar: [],
      load_editar: false,

      headers: [],
      desserts: [],
      cantidad_paginas: 1,
      page: 1,
      search: "",

      items: [],

      load: false,
      notFound: false,

      //Variables agregadas por fry
      nombre_endpoint_almacenamiento: "",
      campo_seleccionado: [],
      tipo_documental_seleccionado: {},
      tipos_documentales: [],
      campos_tipo_documental: [],
      tipos_documentales_completo: [],
      parametro: "",
      archivos: [],
      error: {
        message: "",
      },
      dialogo_revisar_archivo_seleccionado: false,

      //datos_adjuntos: [],
      //formularios: [],

      coleccion: "",
      archivo_seleccionado: {},
      busqueda_inhabilitar: {},
      dialogo_inhabilitar: false,
    };
  },
  watch: {
    page(val) {
      console.log(val);
      this.buscar();
    },
    campo_seleccionado(val) {
      console.log(val);
    },
  },
  async created() {
    console.log("sss");
    this.consultar_tipos_documentales();
  },
  methods: {
    cerrar_revision_archivos() {
      console.log("s");
      this.dialogo_revisar_archivo_seleccionado = false;
      EventBus.$emit("cambiar_carpeta", 0); //Este evento se emite para ir a la caprta inicial de "revusar Archivos"
    },

    actualizar_archivos(informacion) {
      console.log(informacion);
      console.log(this.archivo_seleccionado);
      let index = this.desserts.indexOf(this.archivo_seleccionado);
      console.log(index);
      Object.assign(this.desserts[index], informacion);
      console.log(this.desserts);
    },
    async editar_informacion_endpoint(body, informacion) {
      console.log(informacion);
      this.load_editar = true;
      try {
        let obj = {};
        for (let index = 0; index < body.length; index++) {
          if (body[index].value.toLowerCase() == "fecha") {
            obj.fecha_documento_int = Date.parse(
              new Date(informacion[body[index].value])
            );
          }
          obj[body[index].value] = informacion[body[index].value];
        }
        console.log(obj);
        console.log(this.tipo_documental_seleccionado);
        let coleccion = this.tipo_documental_seleccionado
          .nombre_endpoint_almacenamiento;
        let response = await this.$store.dispatch("llamado_patch", {
          url:
            "https://siett.educandote.co/types/collections/" +
            coleccion +
            "/documents/" +
            informacion.id,
          body: obj,
          tipo_header: "indexador",
        });
        const obj_informacion_editar = {
          usuario: this.$store.state.dataUser.dataUser.objectId,
          coleccion: coleccion,
          id_objeto: informacion.id,
          objeto_editado: this.desserts[informacion.index_editar],
          objeto_nuevo: obj,
        };
        let informar_edicion = await this.$store.dispatch("llamado_post", {
          url:
            "https://siett.educandote.co/parse/classes/prueba01Modificaciones",
          body: obj_informacion_editar,
          tipo_header: "parse",
        });
        console.log(informar_edicion);

        this.$store.dispatch("utils/SAVE_ACTIVITY_USER", {
          data: obj_informacion_editar,
          action: "EDITAR_BUSQUEDA",
        });

        Object.assign(this.desserts[informacion.index_editar], informacion);
        console.log(response);

        this.$store.commit(
          "exitoso",
          "Los campos han sido editados de forma exitosa..."
        );
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado al momento de editar los campos... " +
            error.message
        );
      }
      this.dialogo_editar = false;
      this.load_editar = false;
    },
    set_busqueda_inhabilitar(item) {
      this.busqueda_inhabilitar = item;
      this.dialogo_inhabilitar = true;
    },

    async habilitar_busqueda(informacion) {
      console.log(informacion);
      this.$store.state.dialogCargando = true;
      try {
        let index = this.desserts.indexOf(informacion);
        let coleccion = this.tipo_documental_seleccionado
          .nombre_endpoint_almacenamiento;
        let response = await this.$store.dispatch("llamado_patch", {
          url:
            "https://siett.educandote.co/types/collections/" +
            coleccion +
            "/documents/" +
            informacion.id,
          body: {
            estatus: "HABILITADA",
          },
          tipo_header: "indexador",
        });
        console.log(response);
        let objeto_nuevo = { ...this.desserts[index] };

        objeto_nuevo.estatus = "HABILITADA";

        Object.assign(this.desserts[index], objeto_nuevo);

        //Habilitar carpetas (En caso de querer inhabilitarlas)

        //Inhabilitamos todas las imagenes asociadas a la busqueda si se elimina la busqueda
        let response_imagenes = await this.$store.dispatch("llamado_get", {
          url: `https://siett.educandote.co/types/collections/IMAGENES_${"V1_SIETT_DOCUMENT_V3_2"}/documents/search?q=${
            informacion.id
          }&query_by=${"id_busqueda"}&filter_by=id_busqueda:[${
            informacion.id
          }]&per_page=${250}&sort_by=order:asc`,
          tipo_header: "indexador",
        });

        for (
          let index = 0;
          index < response_imagenes.data.hits.length;
          index++
        ) {
          try {
            let response_edit_imagenes = await this.$store.dispatch(
              "llamado_patch",
              {
                url: `https://siett.educandote.co/types/collections/IMAGENES_${"V1_SIETT_DOCUMENT_V3_2"}/documents/${
                  response_imagenes.data.hits[index].document.id
                }`,
                body: {
                  estado_imagen: "habilitada",
                },
                tipo_header: "indexador",
              }
            );
            console.log(response_edit_imagenes);
          } catch (error) {
            console.log(error);
            this.$store.commit(
              "error",
              "Ha ocurrido un error inesperado habilitando una imagen de la busqueda. " +
                error.message
            );
          }

          try {
            let file_formdata = new FormData();
            file_formdata.append("estado", "enable");
            file_formdata.append(
              "texto",
              response_imagenes.data.hits[index].document.token
            );
            file_formdata.append(
              "nombre",
              `${response_imagenes.data.hits[index].document.nombre}.fry`
            );
            file_formdata.append(
              "filenew",
              `${response_imagenes.data.hits[index].document.nombre}.${response_imagenes.data.hits[index].document.tipo}`
            );
            this.$store.commit("auth");
            let estado_file = await axios.post(
              "https://drive1.educandote.co/siett/estadoIMG.php",
              file_formdata,
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );
            console.log(estado_file);
          } catch (error) {
            console.log(error);
          }
        }

        const obj_informacion_editar = {
          usuario: this.$store.state.dataUser.dataUser.objectId,
          coleccion: coleccion,
          id_objeto: informacion.id,
          objeto_editado: this.desserts[index],
          objeto_nuevo: objeto_nuevo,
        };

        let informar_edicion = await this.$store.dispatch("llamado_post", {
          url:
            "https://siett.educandote.co/parse/classes/prueba01Modificaciones",
          body: obj_informacion_editar,
          tipo_header: "parse",
        });

        console.log(informar_edicion);

        this.$store.dispatch("utils/SAVE_ACTIVITY_USER", {
          data: obj_informacion_editar,
          action: "HABILITAR_BUSQUEDA",
        });

        this.dialogo_inhabilitar = false;
        this.$store.commit("exitoso", "La busqueda ha sido recuperada");
        //this.desserts.splice(index,1);
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado: " + error.message
        );
      }
      this.$store.state.dialogCargando = false;
    },

    async eliminar_busqueda(informacion) {
      console.log(informacion);
      this.$store.state.dialogCargando = true;
      try {
        let index = this.desserts.indexOf(informacion);
        let coleccion = this.tipo_documental_seleccionado
          .nombre_endpoint_almacenamiento;
        let response = await this.$store.dispatch("llamado_patch", {
          url:
            "https://siett.educandote.co/types/collections/" +
            coleccion +
            "/documents/" +
            informacion.id,
          body: {
            estatus: "ERROR_TIPO_2",
          },
          tipo_header: "indexador",
        });
        console.log(response);
        let objeto_nuevo = { ...this.desserts[index] };
        objeto_nuevo.estatus = "ERROR_TIPO_2";

        //Habilitar carpetas (En caso de querer inhabilitarlas)

        //Inhabilitamos todas las imagenes asociadas a la busqueda si se elimina la busqueda
        let response_imagenes = await this.$store.dispatch("llamado_get", {
          url: `https://siett.educandote.co/types/collections/IMAGENES_${"V1_SIETT_DOCUMENT_V3_2"}/documents/search?q=${
            informacion.id
          }&query_by=${"id_busqueda"}&filter_by=id_busqueda:[${
            informacion.id
          }]&per_page=${250}&sort_by=order:asc`,
          tipo_header: "indexador",
        });

        for (
          let index = 0;
          index < response_imagenes.data.hits.length;
          index++
        ) {
          try {
            let response_edit_imagenes = await this.$store.dispatch(
              "llamado_patch",
              {
                url: `https://siett.educandote.co/types/collections/IMAGENES_${"V1_SIETT_DOCUMENT_V3_2"}/documents/${
                  response_imagenes.data.hits[index].document.id
                }`,
                body: {
                  estado_imagen: "ERROR_TIPO_2",
                },
                tipo_header: "indexador",
              }
            );
            console.log(response_edit_imagenes);
          } catch (error) {
            console.log(error);
            this.$store.commit(
              "error",
              "Ha ocurrido un error inesperado eliminando una imagen de la busqueda. " +
                error.message
            );
          }
        }

        const obj_informacion_editar = {
          usuario: this.$store.state.dataUser.dataUser.objectId,
          coleccion: coleccion,
          id_objeto: informacion.id,
          objeto_editado: this.desserts[index],
          objeto_nuevo: objeto_nuevo,
        };

        let informar_edicion = await this.$store.dispatch("llamado_post", {
          url:
            "https://siett.educandote.co/parse/classes/prueba01Modificaciones",
          body: obj_informacion_editar,
          tipo_header: "parse",
        });

        console.log(informar_edicion);

        this.$store.dispatch("utils/SAVE_ACTIVITY_USER", {
          data: obj_informacion_editar,
          action: "ERROR_TIPO_2",
        });

        this.dialogo_inhabilitar = false;
        this.$store.commit("exitoso", "La busqueda ha sido eliminada");
        this.desserts.splice(index, 1);
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado: " + error.message
        );
      }
      this.$store.state.dialogCargando = false;
    },

    async inhabilitar_busqueda(informacion) {
      console.log(informacion);
      this.$store.state.dialogCargando = true;
      try {
        let index = this.desserts.indexOf(informacion);
        let coleccion = this.tipo_documental_seleccionado
          .nombre_endpoint_almacenamiento;
        let response = await this.$store.dispatch("llamado_patch", {
          url:
            "https://siett.educandote.co/types/collections/" +
            coleccion +
            "/documents/" +
            informacion.id,
          body: {
            estatus: "INHABILITADA",
          },
          tipo_header: "indexador",
        });
        console.log(response);
        let objeto_nuevo = { ...this.desserts[index] };
        objeto_nuevo.estatus = "INHABILITADA";

        //Habilitar carpetas (En caso de querer inhabilitarlas)

        //Inhabilitamos todas las imagenes asociadas a la busqueda si se elimina la busqueda
        let response_imagenes = await this.$store.dispatch("llamado_get", {
          url: `https://siett.educandote.co/types/collections/IMAGENES_${"V1_SIETT_DOCUMENT_V3_2"}/documents/search?q=${
            informacion.id
          }&query_by=${"id_busqueda"}&filter_by=id_busqueda:[${
            informacion.id
          }]&per_page=${250}&sort_by=order:asc`,
          tipo_header: "indexador",
        });

        for (
          let index = 0;
          index < response_imagenes.data.hits.length;
          index++
        ) {
          try {
            let response_edit_imagenes = await this.$store.dispatch(
              "llamado_patch",
              {
                url: `https://siett.educandote.co/types/collections/IMAGENES_${"V1_SIETT_DOCUMENT_V3_2"}/documents/${
                  response_imagenes.data.hits[index].document.id
                }`,
                body: {
                  estado_imagen: "INHABILITADA",
                },
                tipo_header: "indexador",
              }
            );
            console.log(response_edit_imagenes);
          } catch (error) {
            console.log(error);
            this.$store.commit(
              "error",
              "Ha ocurrido un error inesperado eliminando una imagen de la busqueda. " +
                error.message
            );
          }

          try {
            let file_formdata = new FormData();
            file_formdata.append("estado", "disable");
            file_formdata.append(
              "texto",
              response_imagenes.data.hits[index].document.token
            );
            file_formdata.append(
              "nombre",
              `${response_imagenes.data.hits[index].document.nombre}.${response_imagenes.data.hits[index].document.tipo}`
            );
            this.$store.commit("auth");
            let estado_file = await axios.post(
              "https://drive1.educandote.co/siett/estadoIMG.php",
              file_formdata,
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );
            console.log(estado_file);
          } catch (error) {
            console.log(error);
          }
        }

        const obj_informacion_editar = {
          usuario: this.$store.state.dataUser.dataUser.objectId,
          coleccion: coleccion,
          id_objeto: informacion.id,
          objeto_editado: this.desserts[index],
          objeto_nuevo: objeto_nuevo,
        };

        let informar_edicion = await this.$store.dispatch("llamado_post", {
          url:
            "https://siett.educandote.co/parse/classes/prueba01Modificaciones",
          body: obj_informacion_editar,
          tipo_header: "parse",
        });

        console.log(informar_edicion);

        this.$store.dispatch("utils/SAVE_ACTIVITY_USER", {
          data: obj_informacion_editar,
          action: "INHABILITAR_BUSQUEDA",
        });

        this.dialogo_inhabilitar = false;
        this.$store.commit("exitoso", "La busqueda ha sido eliminada");
        this.desserts.splice(index, 1);
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado: " + error.message
        );
      }
      this.$store.state.dialogCargando = false;
    },

    editar_inputs(item) {
      console.log(item);
      let index = this.desserts.indexOf(item);
      this.informacion_editar = { ...item, index_editar: index };
      this.dialogo_editar = true;
    },
    async revisar_archivo_seleccionado(item) {
      console.log(item);

      try {
        this.$store.state.buscador.datos_adjuntos = [];
        this.$store.state.buscador.formularios = [];
        this.$store.state.buscador.carpetas = [];
        this.$store.state.buscador.coleccion_archivo_seleccionado =
          "V1_SIETT_DOCUMENT_V3_2";

        this.$store.state.buscador.archivo_seleccionado = item;

        await this.$store.dispatch("buscador/GET_CARPETAS");
        await this.$store.dispatch(
          "buscador/GET_ARCHIVOS_CARPETA_SELECCIONADA",
          this.$store.state.buscador.carpetas[0]
        );

        this.dialogo_revisar_archivo_seleccionado = true;
      } catch (error) {
        console.log(error);
      }
    },
    revisar(item) {
      console.log(item);

      this.headers = [
        { value: "actions", text: "Eliminar/Editar" },
        { value: "Placa", text: "Placa" },
        { value: "Rango_placa", text: "Rango" },
        { value: "Numero_rango_placa", text: "Numero rango placa" },
        { value: "Parque_automotor", text: "Parque automotor" },
        //{value: 'Parque_auto_motor',text:'Parque automotor'},
        { value: "Tipo_de_tramite", text: "Tipo de tramite" },
        { value: "Fecha", text: "Fecha" },
        { value: "Tipo_de_vehiculo", text: "Vehiculo" },
        {
          value: "Documento_del_propietario",
          text: "Documento del propietario",
        },
        { value: "Observaciones", text: "Observaciones" },
        { value: "estatus", text: "Estatus" },
        { value: "autor", text: "Autor" },
      ];

      this.headers_editar = [
        { value: "Placa", text: "Placa" },
        { value: "Rango_placa", text: "Rango" },
        { value: "Numero_rango_placa", text: "Numero rango placa" },
        { value: "Parque_automotor", text: "Parque automotor" },
        //{value: 'Parque_auto_motor',text:'Parque automotor'},
        { value: "Tipo_de_tramite", text: "Tipo de tramite" },
        { value: "Fecha", text: "Fecha" },
        { value: "Tipo_de_vehiculo", text: "Vehiculo" },
        {
          value: "Documento_del_propietario",
          text: "Documento del propietario",
        },
        { value: "Observaciones", text: "Observaciones" },
        //{value: 'autor',text:'Autor'},
      ];

      /*
       let aux2
      Object.keys(item).forEach( (key) => {
          console.log(key)
          aux2 = key.replace(/_/g, " ")
        
          if(key!='Placa' && key!='fecha_creacion_int' && key!="extended_properties" && key !='order' && key != 'fecha_documento_int' && key!= 'id_Tipo_de_tramite' && key!= 'relacionado_con' && key!= 'Relacionado_con' ){
              
              this.headers.push({
                value: key,
                text: aux2,
              });
          }
          if(key!='Placa' && key!='id' && key!='fecha_creacion_int' && key!="extended_properties" && key !='order' && key != 'fecha_documento_int' && key!= 'id_Tipo_de_tramite' && key!= 'relacionado_con' && key!= 'Relacionado_con'   ){
            this.headers_editar.push({
              value: key,
              text: aux2,
            })
          }
          
        })
        */

      this.headers.push({
        value: "datos_archivos",
        text: "Archivos",
      });
    },

    async consultar_tipos_documentales() {
      const response = await this.$store.dispatch("llamado_get", {
        url: `https://siett.educandote.co/parse/classes/prueba01Sector/${this.$store.state.dataUser.dataUser.sector}`,
        tipo_header: "parse",
      });
      console.log(response);

      this.tipos_documentales = [...response.data.tipos_documentales];
    },
    tipoFiltro() {
      console.log(this.tipo_documental_seleccionado);
      this.campos_tipo_documental = [];

      this.nombre_endpoint_almacenamiento = this.tipo_documental_seleccionado.nombre_endpoint_almacenamiento;

      let aux = [];
      for (
        let index = 0;
        index < this.tipo_documental_seleccionado.parametros.length;
        index++
      ) {
        let obj = {
          nombre: this.tipo_documental_seleccionado.parametros[index].name,
          nombre_endpoint: this.tipo_documental_seleccionado.parametros[index]
            .key,
          valor: "",
        };

        if (
          this.tipo_documental_seleccionado.parametros[index].key !=
          "id_Tipo_de_tramite"
        ) {
          aux.push(obj);

          if (obj.nombre.toLowerCase() == "fecha") {
            aux.push({
              nombre: "Rango de fecha",
              nombre_endpoint: "Rango_de_fecha",
              query_by: obj.nombre_endpoint,
              valor: "",
            });
          }
        }
      }

      console.log(aux);

      this.campos_tipo_documental = aux;

      console.log(this.campos_tipo_documental);

      //this.campos_tipo_documental = [...this.tipo_documental_seleccionado.parametros];
    },
    buscar() {
      console.log(this.campo_seleccionado);

      this.load = true;
      let _this = this;
      //replace(/ /g, "")
      console.log(this.tipo_documental_seleccionado);
      let coleccion = this.tipo_documental_seleccionado
        .nombre_endpoint_almacenamiento;
      //coleccion = 'pruebaExpedientedecontribuyentepredial'

      //let  campo = this.campo_seleccionado.replace(/ /g, "_");
      //campo = 'Referencia_catastral'

      let busqueda = this.campo_seleccionado[0].valor;
      let query_by = this.campo_seleccionado[0].nombre_endpoint;

      if (this.campo_seleccionado[0].nombre_endpoint == "Rango_de_fecha") {
        busqueda = "*";
        query_by = this.campo_seleccionado[0].query_by;
      }

      let filter_by = ``;

      let length = this.campo_seleccionado.length;
      for (let index = 0; index < length; index++) {
        if (
          this.campo_seleccionado[index].nombre_endpoint != "Rango_de_fecha"
        ) {
          filter_by =
            filter_by +
            `${this.campo_seleccionado[index].nombre_endpoint}:["${this.campo_seleccionado[index].valor}"]&&`;
        } else {
          let fechas = this.campo_seleccionado[index].valor.split(" ");
          let fecha_inicial = Date.parse(new Date(fechas[0]));
          let fecha_final = Date.parse(new Date(fechas[1]));
          filter_by =
            filter_by +
            `fecha_documento_int:[${fecha_inicial}..${fecha_final}]&&`;
        }
      }

      if (
        this.$store.state.dataUser.permisos_buscador["Buscador completo"] ==
        true
      ) {
        filter_by = filter_by + `estatus:[${"HABILITADA"},${"INHABILITADA"}]`;
      } else {
        filter_by = filter_by + `estatus:[${"HABILITADA"}]`;
      }

      console.log(filter_by);

      _this.desserts = [];

      axios
        .get(
          "https://siett.educandote.co/types/collections/" +
            coleccion +
            "/documents/search?q=" +
            busqueda +
            "&query_by=" +
            query_by +
            "&filter_by=" +
            filter_by +
            "&per_page=250&page=" +
            this.page,
          {
            headers: {
              "Content-Type": "application/json",
              "X-TYPESENSE-API-KEY":
                "S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe",
            },
          }
        )
        .then(function(response) {
          console.log(response);

          if (response.data.found >= 1) {
            _this.revisar(response.data.hits[0].document);
            for (let index = 0; index < response.data.hits.length; index++) {
              _this.desserts.push(response.data.hits[index].document);

              console.log(response.data.hits[index].document);
            }
            console.log(_this.desserts);
            console.log(_this.headers);
            _this.step = 2;
            _this.notFound = false;
            _this.cantidad_paginas = parseInt(response.data.found / 250) + 1;
          } else {
            _this.notFound = true;
          }
          _this.load = false;
        })
        .catch(function(error) {
          console.log(error);
          _this.error = error;
          _this.notFound = true;
          _this.load = false;
        });
    },
  },
};
</script>
