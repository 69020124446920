<template>
    <v-sheet>
        <v-row>
                <v-col cols="12" sm="3">
                    <Toolbar 
                    :titulo="'Carpetas'" 
                    :activar_tool="$store.state.dataUser.permisos_buscador.Agregar" 
                    :accion="'Crear carpeta'" 
                    :icono="'mdi-folder-multiple-plus-outline'"
                    @activar="dialogo_crear_carpeta=true" 
                />
                <v-text-field
                    v-model="busar_carpeta"
                    filled rounded dense
                    label="Buscar expediente"
                ></v-text-field>
                <v-virtual-scroll
                    :bench="0"
                    :items="carpeta_filtradas"
                    height="650"
                    item-height="64"
                >
                    <template v-slot:default="{ item }">
                            <v-list-item v-if="item.estado_carpeta=='ACTIVO'"  @click="get_archivos_carpeta_seleccionada(item)">
                                <v-list-item-avatar>
                                    <v-icon :color="color(item)" class="grey lighten-1" dark >mdi-folder</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.nombre_carpeta"></v-list-item-title>

                                </v-list-item-content>

                                <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="green lighten-1">mdi-information</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-else>
                                <v-list-item-avatar>
                                    <v-icon :color="'red'" dark >mdi-folder</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="'Eliminada '+item.nombre_carpeta"></v-list-item-title>

                                </v-list-item-content>

                                <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="red lighten-1">mdi-information</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-col>
            <v-col cols="12" sm="9">
                
                <ToolbarMultiIcons 
                    :titulo="'Archivos de: '+$store.state.buscador.carpeta_seleccionada.nombre_carpeta" 
                    :activar_tool="true"
                    
                    :actions="actions_available"
                    @upload_files="dialogo_agregar_archivos_carpeta=true"
                    @download_files="download_files($store.state.buscador.archivos_carpeta_seleccionada)"
                    @edit_folder="dialogo_edit_folder=true"
                    @delete_folder="dialogo_delete_folder=true"
                    @full_query="dialog_archivos_inhabilitados=true"
                    @add_documents_manual="dialog_add_documents_manual=true"



                />
                <ArchivosCarpetaSeleccionada  
                    :archivos_carpeta_seleccionada="$store.state.buscador.archivos_carpeta_seleccionada" 
                />
            </v-col>
        </v-row>

        <v-dialog v-model="dialog_archivos_inhabilitados" max-width="700"  >
            <Card :titulo="'Archivos inhabilitados'" @activar="dialog_archivos_inhabilitados=false">
                <template v-slot:contenido>
                    <ArchivosCarpetaSeleccionada
                     
                        :actions="$store.state.dataUser.permisos_buscador['Buscador completo']==true ? 'RELOAD_FILE' : 'NO_ACTIONS'" 
                        :archivos_carpeta_seleccionada="$store.state.buscador.archivos_inhabilitados_carpeta_seleccionada" 
                    />
                </template>
            </Card>
        </v-dialog>
        
        
        <v-dialog v-model="dialogo_crear_carpeta" persistent max-width="600" >
            <Card :titulo="'Crear carpeta'" @activar="cerrar_dialogo_crear_carpeta()" >
                <template v-slot:contenido>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <v-text-field :rules="[
                                        (v) => !!v || 'Es requerido asignar un nombre para la carpeta',
                                        ]" required 
                                    v-model="nueva_carpeta.nombre_carpeta" filled rounded dense label="Nombre carpeta"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            
                            <v-col>
                                <v-btn block @click="crear_carpeta(nueva_carpeta)" :disabled="!valid" style="color:white" color="indigo darken-4">Crear nueva carpeta </v-btn>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_edit_folder" max-width="500">
            <Card :titulo="'Editar carpeta '+$store.state.buscador.carpeta_seleccionada.nombre_carpeta" @activar="dialogo_edit_folder=false" >
                <template v-slot:contenido>
                    
                    <v-card-text>
                        <v-text-field filled rounded dense label="Nombre de la carpeta" v-model="name_folder" ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="editar_carpeta()" 
                                block dark 
                                color="green"
                        > 
                            Editar <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                    </v-card-actions>
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_delete_folder" max-width="500" >
            <Card :titulo="'Eliminar carpeta'" :color="'red'" @activar="dialogo_delete_folder=false" >
                <template v-slot:contenido  >
                    <v-divider class="my-3"></v-divider>
                    <h2 style="text-align:center"> Estás seguro que deseas eliminar la carpeta: {{$store.state.buscador.carpeta_seleccionada.nombre_carpeta}} </h2>
                    <v-divider class="my-3"></v-divider>
                    <v-card-actions>
                        <v-btn block color="red" dark @click="delete_folder()" > Eliminar <v-icon>mdi-delete</v-icon> </v-btn>
                    </v-card-actions>
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_agregar_archivos_carpeta" max-width="600" persistent >
                <AgregarArchivos 
                    @cerrar="actualizar_datos_carpeta()"
                />
        </v-dialog>
        <v-dialog v-model="dialog_download" max-width="600" persistent >
                <Card :titulo="'Descargando carpeta...'" @activar="dialog_download=false" >
                    <template v-slot:contenido>
                        
                        <Load :load="load" />
                        <h3 v-if="load" style="text-align:center"> Espera un momento por favor...</h3>
                        <h3 style="text-align:center" v-if="result_download">Procesamiento exitoso</h3>
                        <v-img class="mx-auto" contain max-width="300" :src="require('../../assets/load.png')">

                        </v-img>

                        <v-row>
                            <v-col v-if="result_download==true">
                                <v-btn
                                    
                                    
                                    dark
                                    color="indigo darken-4"
                                    block
                                    
                                    >
                                    <a :href="url_pdf_download" target="_blank">
                                        Haz click aquí para descargar
                                        <v-icon>mdi-download</v-icon>
                                    </a>
                                </v-btn>
                            </v-col>
                            <v-col v-else-if="result_download==false">
                                <h3>Algo ha salido mal descargando el archivo, por favor intentalo más tarde</h3>
                            </v-col>
                        </v-row>
                    </template>
                    
                    
                </Card>
        </v-dialog>
        <v-dialog v-model="dialog_add_documents_manual" max-width="600" persistent >
                <Card :titulo="'Add carpeta...'" @activar="dialog_add_documents_manual=false" >
                    <template v-slot:contenido>
                        
                        <v-row>
                            <v-col>
                                <v-text-field label="Paginas a agregar" v-model="paginas_to_add_manual"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-btn block dark @click="add_documents_manual()">Agregar </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    
                    
                </Card>
        </v-dialog>
    </v-sheet>
</template>
<script>
//import { mapState } from 'vuex';
import ToolbarMultiIcons from '../atomos/ToolbarMultiIcons.vue'
import ArchivosCarpetaSeleccionada from './ArchivosCarpetaSeleccionada'
import AgregarArchivos from './AgregarArchivos.vue'
const axios = require("axios").default; //import  jsPDF  from "jspdf";
import folders from '../../mixins/folders'
import Card from '../celulas/Card.vue';

export default {
    mixins: [folders],
    props: {
       carpetas: {
           type: Array,
           required: true,
       }
    },
    components: {
    ToolbarMultiIcons,
    ArchivosCarpetaSeleccionada,
    AgregarArchivos,
    Card
},
    data(){
        return {
            busar_carpeta: "",
            nueva_carpeta: {
                nombre_carpeta: "",
            },


            valid: true,

            carpeta_filtradas: [],
            dialogo_crear_carpeta: false,
            dialogo_delete_folder: false,
            dialogo_edit_folder: false,
            dialogo_agregar_archivos_carpeta: false,
            dialog_add_documents_manual: false,

            paginas_to_add_manual: 0,

            name_folder: "",
            load_create_folder: false,

            actions_available: [],
            dialog_archivos_inhabilitados: false,
            dialog_download: false,
            result_download: null,
            url_pdf_download: "",

            load: false,
           
        }
    },
    watch: {
        carpetas(val){
            
            this.carpeta_filtradas = [...val]
        },
        busar_carpeta(val){
            val = val.toLowerCase()
            this.carpeta_filtradas = this.carpetas.filter( item => item.nombre_carpeta.toLowerCase().indexOf(val) !== -1 )
        },
    },
    created(){

        

        this.carpeta_filtradas = [...this.carpetas];
        this.$store.state.buscador.carpeta_seleccionada = this.carpeta_filtradas[0];
        const actions = [ 

            {icon: 'mdi-file-document-multiple-outline', name: 'Agregar archivos', value:'upload_files', key: 'Agregar'},
            {icon: 'mdi-download', name: 'Descargar archivos', value:'download_files', key: 'Descargar' },
            {icon: 'mdi-pencil', name: 'Editar carpeta', value: 'edit_folder', key: 'Editar'},
            {icon: 'mdi-delete', name: 'Eliminar carpeta', value: 'delete_folder', key: 'Eliminar'},
            {icon: 'mdi-reload', name: 'Archivos Inhabilitados', value: 'full_query', key: 'Buscador completo'},
        ];

        for (let index = 0; index < actions.length; index++) {
           if(this.$store.state.dataUser.permisos_buscador[actions[index].key]){
                this.actions_available.push(actions[index]);
           }  
        }

        console.log('NEW FRY')
        console.log(this.$store.state.dataUser.dataUser);

        if(this.$store.state.dataUser.dataUser.objectId=="bRSwTdK6aF"){
            this.actions_available.push({icon: 'mdi-plus', name: 'Agregar archivos', value: 'add_documents_manual', key: 'Agregar documentos'})
            this.actions_available.push({icon: 'mdi-minus', name: 'Eliminar archivos', value: 'delete_documents_manual', key: 'Delete documentos'},)
        }

    },
    methods: {
        async add_documents_manual(){

            try {
                console.log(this.paginas_to_add_manual);

                console.log(this.$store.state.buscador.carpeta_seleccionada)

                console.log(this.$store.state.buscador.archivo_seleccionado);

                console.log(this.$store.state.buscador.archivos_carpeta_seleccionada);

                let body_imagenes = "";
                //let body = {};
                    let name_images = [];
                  
                    for (let index = 0; index < parseInt(this.paginas_to_add_manual); index++) {
                      
                      body_imagenes = body_imagenes+`{"id_busqueda": "${this.$store.state.buscador.archivo_seleccionado.id}", "id_carpeta": "${this.$store.state.buscador.carpeta_seleccionada.id}", "autor": "${this.$store.state.buscador.archivo_seleccionado.autor}", "estado_imagen": "${'INHABILITADA'}", "meta_datos": "", "nombre": "${index+1}", "nombre_sector": "${this.$store.state.buscador.archivos_carpeta_seleccionada[0].nombre_sector}", "sector": "${this.$store.state.buscador.archivos_carpeta_seleccionada[0].sector}", "tipo": "${'png'}", "token": "${this.$store.state.buscador.archivos_carpeta_seleccionada[0].token}", "fecha_creacion_int": ${this.$store.state.buscador.archivos_carpeta_seleccionada[0].fecha_creacion_int}, "order": ${this.$store.state.buscador.archivos_carpeta_seleccionada[0].order}, "extended_properties": "{}","imagenes_por_archivo": "1"}`+'\n';
                      name_images.push(index+1);
                    }

                    console.log(body_imagenes);
                    
                    if(body_imagenes=="FRY"){
                        await this.set_images(body_imagenes);

                        const result = await this.get_ids_folder(this.$store.state.buscador.archivos_carpeta_seleccionada[0].token);
                        const collections = 'V1_SIETT_DOCUMENT_V3_2'
                        const archivos = await this.$store.dispatch('llamado_post',{
                            url:`https://siett.educandote.co/parse/classes/IMAGENESV3_${collections}`,
                            body: {
                                id_images:result.id_images,
                                id_pdf: result.id_pdf,
                                folder: this.$store.state.buscador.archivos_carpeta_seleccionada[0].token,
                                method: "PATCH",
                                url_typesense: `https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/`          
                                ,date: this.$store.state.buscador.archivos_carpeta_seleccionada[0].fecha_creacion_int,
                                body_for_enable: {
                                estado_imagen: 'habilitada'
                                },
                                body_for_disable: {
                                estado_imagen: 'INHABILITADA'
                                },
                                name_images: name_images,
                                name_pdf: this.$store.state.buscador.archivos_carpeta_seleccionada[0].nombre,
                            },
                            tipo_header: "parse"
                        });

                        console.log(archivos);
                    }

            } catch (error) {
                console.log(error);
            }
        },
        async get_ids_folder(token){
            let result = {
            id_images: [],
            id_pdf: ""
            };

            try {
            
            console.log(token);
            const response = await this.$store.dispatch('llamado_get',{
                    url:`https://siett.educandote.co/types/collections/IMAGENES_${'V1_SIETT_DOCUMENT_V3_2'}/documents/search?q=${token}&query_by=${'token'}&filter_by=token:[${token}]&per_page=${250}&sort_by=order:asc`,
                    tipo_header: "indexador"
            });

            
            for (let index = 0; index < response.data.hits.length; index++) {

                if(response.data.hits[index].document.tipo=='pdf'){
                    result.id_pdf = response.data.hits[index].document.id;
                }else{
                    result.id_images.push(response.data.hits[index].document.id);
                }
                
                
            }
            console.log(result);

            //console.log(ids);

            

            } catch (error) {
            console.log(error);
            }
            return result;
        },
        async set_images(body){

            console.log(body);

            try {
                const collections = 'V1_SIETT_DOCUMENT_V3_2'
                const imagenes = await this.$store.dispatch('llamado_post',{
                    url:`https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
                    body: body,
                    tipo_header: "indexador"
                });

                console.log(imagenes)
            } catch (error) {
                console.log(error);
            }

        },
        actualizar_datos_carpeta(){
            this.get_archivos_carpeta_seleccionada(this.$store.state.buscador.carpeta_seleccionada);
            this.dialogo_agregar_archivos_carpeta = false;
        },
        cerrar_dialogo_crear_carpeta(){
            this.dialogo_crear_carpeta = false;
        },
        color(item){
            if(typeof item.estado!='undefined' )
            console.log(item)
            return 'yellow'
        },
        async crear_carpeta(nueva_carpeta){
            console.log(nueva_carpeta);
            let response = await this.$store.dispatch('buscador/CREAR_CARPETA',nueva_carpeta);

            if(response){
                
                await this.$store.dispatch('buscador/GET_CARPETAS');
                this.carpeta_filtradas = [...this.carpetas];

                
                console.log(this.carpetas);

                this.dialogo_crear_carpeta = false;
                this.$store.commit(
                        "exitoso",
                        "La carpeta ha sido creada de forma exitosa"
                    );
            }else{
                
                this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de crear la carpeta "
                    );
            }
        },

        async get_archivos_carpeta_seleccionada(carpeta){
            
            console.log(this.$store.state.buscador.archivo_seleccionado);
            console.log(carpeta);
            try {

                //this.$store.state.buscador.carpeta_seleccionada //= {...carpeta};
                

                await this.$store.dispatch('buscador/GET_ARCHIVOS_CARPETA_SELECCIONADA',this.$store.state.buscador.carpeta_seleccionada);
            } catch (error) {
                console.log(error);
                this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de obtener la carpeta "+error.message
                );
            }
        },
        async editar_carpeta() {

            try {
                let carpeta = {
                    id: this.$store.state.buscador.carpeta_seleccionada.id, 
                    nombre_carpeta: this.name_folder 
                }

                const response = await this.$store.dispatch('buscador/EDITAR_CARPETA',carpeta);
                if(response){
                    this.$store.commit(
                        "exitoso",
                        "La carpeta ha sido editada de forma exitosa"
                    ); 

                    this.$store.state.buscador.carpeta_seleccionada.nombre_carpeta = this.name_folder;

                    this.name_folder = "";
                    this.dialogo_edit_folder = false;
                }else{
                    this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de eliminar la carpeta"
                    );
                }
            } catch (error) {
                console.log(error);
            }

        },
        async delete_folder() {
            try {
                const response = await this.$store.dispatch('buscador/ELIMINAR_CARPETA',this.$store.state.buscador.carpeta_seleccionada)

                if(response){
                    this.$store.commit(
                        "exitoso",
                        "La carpeta ha sido eliminada de forma exitosa"
                    ); 

                    await this.$store.dispatch('buscador/GET_CARPETAS');
                    
                    this.$store.state.buscador.carpeta_seleccionada = this.$store.state.buscador.carpetas[0];

                    this.dialogo_delete_folder = false;
                }else{
                    this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de eliminar la carpeta"
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },

        async download_files(files){
            this.load = true;
            this.dialog_download = true;
            
            console.log(files);

    
            let filesMap = files.map(item=>{
                return [item.token,item]
            });
            var fileMapArr = new Map(filesMap); // Pares de clave y valor

            let unicos = [...fileMapArr.values()]; // Conversión a un array

            console.log(unicos);

            console.log(this.$store.state.buscador.archivo_seleccionado);
            
            let folders = "";

            for (let index = 0; index < unicos.length; index++) {

                if(index==0){
                    folders = unicos[index].token;
                }else{
                    folders = `${folders};${unicos[index].token}`;
                }
            }

            console.log(folders);


            const nombre = `${this.$store.state.buscador.archivo_seleccionado.Placa}.pdf`
            let file = new FormData();
            
            file.append("texto", folders);
            file.append("nombre", nombre);

            
            try {
                const generate_pdf = await axios.post(
                    "https://drive1.educandote.co/siett/processPDFs.php",
                    file,
                    {
                        header: {
                        "Content-Type": "multipart/form-data",
                        Authorization: this.$store.state.header,
                        },
                    }
                );
                console.log(generate_pdf);
                this.url_pdf_download = `https://drive1.educandote.co/siett/${unicos[0].token}/_${nombre}`
                this.$store.dispatch('utils/SAVE_ACTIVITY_USER',{data: files, action: "DESCARGA"});  
                this.result_download = true;

                
            
            } catch (error) {
                console.log(error);
                this.result_download = false;
            }
            this.load = false;
            /*
            
            
            let no_image_files = [];

            //Registro de quien descargo la carpeta,

           
           
           
           try {
               console.log('descargar');
                console.log(files)
                var doc = new jsPDF('p', 'mm','a4');
                let altura = 0
                for (let index = 0; index < files.length; index++) {
                    if(files[index].tipo.toLowerCase()=='blob'||files[index].tipo.toLowerCase()=='jpg'||files[index].tipo.toLowerCase()=='jpeg'||files[index].tipo.toLowerCase()=='png'||files[index].tipo.toLowerCase()=='bmp'){
                        doc.addImage(
                            this.urlRespuestas(
                            files[index],
                            'https://drive1.educandote.co/siett'
                            )
                            ,"JPEG" ,0, altura,200 ,280
                        )
                        //Evita que se inserte una pagina en blanco al final 
                        if(index+1 != files.length ){
                            doc.addPage('a4','p')
                        }
                    }else{
                        no_image_files.push({page:index+1})
                    }
                    
                        
                }
                if(no_image_files.length>0){
                    let message = "";
                    for (let index = 0; index < no_image_files.length; index++) {
                        message = message + " "+ no_image_files[index].page
                        
                    }
                    this.$store.commit(
                                    "error",
                                    "Los archivos de las paginas: "+message+ " No corresponden a imagenes y no pueden ser descargadas de forma automatica, por favor descarguelos de forma manual"
                                );
                }else{
                    doc.save('Carpeta.pdf');
                    this.$store.commit(
                                    "exitoso",
                                    "Los archivos han sido descargador de forma exitosa"
                                ); 
                }   
           } catch (error) {
               console.log(error)
               this.$store.commit(
                    "error",
                    "Ha ocurrido un error inesperado en el momento de descargar una imagen "+error.message
                );
           }

           */
           
           this.$store.state.dialogCargando = false
        },
    },
    
    
    
}
</script>